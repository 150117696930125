import React from 'react'

function Products() {

    const icons = [
        {
            title: "title",
            image: "https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/iconos%20movers%2Ftuberia-de-agua.png?alt=media&token=644680fd-f3bb-406f-b9e8-0edbf960cc1b",
        },
        {
            title: "title",
            image: "https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/iconos%20movers%2Fregadera-removebg-preview.png?alt=media&token=b726d375-ea23-48c5-a3b2-1ece2601227c",
        },
        {
            title: "title",
            image: "https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/iconos%20movers%2FHeater-icon.png?alt=media&token=446bef29-b533-4fab-b594-bf9baffb519a",
        },
        {
            title: "title",
            image: "https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/iconos%20movers%2Ftuberia.png?alt=media&token=1be3b2e0-931e-4c07-9ed5-b6dc57563a4d",
        },
        {
            title: "title",
            image: "https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/iconos%20movers%2Fcasa_tejas.png?alt=media&token=16e0a592-8be7-4c22-ab50-9a51db9e090f",
        },
    ]

    return (
      <div className='justify-center flex flex-wrap gap-5'>
        {icons.map((icon, index) => {
            return (
                <>
                 <div key={index} className='bg-white rounded justify-center items-center flex p-2'>
                    <img src={icon.image} alt={icon.title} className='h-[200px] w-[200px]'/>
                 </div>
                </>
            );
        })}
      </div>
    )
}

export default Products
